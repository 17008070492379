export const API_URL = 'https://api.refnow.co/v1';

//Referencing
export const GET_CANDIDATE_REFERENCES_URL = '/get/employer/request/';
export const GET_CANDIDATES_URL = '/get/employer/requests';
export const GET_CANDIDATE_REFERENCE_URL = '/get/employer/reference/';
export const GET_EMPLOYER_ACCOUNT = '/get/employer/account';
export const GET_PDF_REPORT_URL = '/get/employer/pdf/request/';
export const POST_REFERENCE_REQUEST_URL = '/post/employer/request';
export const POST_REFERENCE_REQUEST_REMINDER_URL = '/post/employer/request-notif';
export const POST_CANCEL_REQUEST_REMINDER_URL = '/post/employer/cancel/request';
export const POST_CANCEL_REFEREE_REMINDER_URL = '/post/employer/cancel/reference';
export const POST_SEND_CANDIDATE_REMINDER_URL = '/post/employer/sendreminder/request';
export const POST_SEND_REFEREE_REMINDER_URL = '/post/employer/sendreminder/reference';
export const POST_DELETE_REFERENCE_URL = '/post/employer/delete/reference';
export const POST_EDIT_NUM_OF_REFERENCES_URL = '/post/employer/edit/request-refnum';
export const POST_DELETE_CANDIDATE_URL = '/post/employer/delete/request';

//Users and Teams
export const GET_USER_URL = '/get/employer/user/';
export const GET_ADMIN_USERS_URL = '/get/employer/users';
export const GET_TEAMS_URL = '/get/employer/teams';
export const GET_ROLES_URL = '/get/employer/roles';
export const GET_USERS_FOR_FILTERS_URL = '/get/employer/request-filter/users';
export const GET_TEAMS_FOR_FILTERS_URL = '/get/employer/request-filter/teams';
export const POST_CREATE_NEW_USER_URL = '/post/employer/user/add';
export const POST_ADMIN_USER_UPDATE_URL = '/post/employer/user/update';
export const POST_ADMIN_USER_DELETE_URL = '/post/employer/user/delete';
export const POST_ADMIN_TEAM_UPDATE_URL = '/post/employer/team/update';
export const POST_ADMIN_TEAM_DELETE_URL = '/post/employer/team/delete';
export const POST_CREATE_NEW_TEAM_URL = '/post/employer/team/add';

//Login and Registration
export const POST_LOGIN_URL = '/post/employer/login';
export const POST_LOGOUT_URL = '/post/employer/logout';
export const POST_SSO_LOGIN_URL = '/post/employer/auth/check-sso';
export const POST_ACTIVATION_URL = '/post/employer/activate';
export const POST_REGISTER_URL = '/post/employer/signup';

//Subscriptions & Billing
export const GET_SUBSCRIPTION = '/get/subscription';
export const POST_SUBSCRIPTION = '/post/payment/subscription';
export const GET_TOP_UP_DETAILS = '/get/subscription/topup';
export const POST_TOP_UP_PAYMENT = '/post/payment/topup';

//Question Profiles
export const GET_QUESTION_PROFILES_URL = '/get/employer/questionprofiles';
export const GET_QUESTION_PROFILE_URL = '/get/employer/questionprofile/';
export const POST_QUESTION_PROFILE_URL = '/post/employer/questionprofile/add';
export const DELETE_QUESTION_URL = '/post/employer/question/delete';
export const POST_NEW_QUESTION_URL = '/post/employer/question/add';
export const GET_PROFILES_LIST_URL = '/get/employer/profiles/list';
export const DELETE_PROFILE_URL = '/post/employer/questionprofile/delete';
export const REORDER_QUESTIONS_URL = '/post/employer/questionprofile/reorder';
export const POST_CLONE_PROFILE_URL = '/post/employer/questionprofile/clone';

//Talent Pool
export const GET_TALENT_POOL_LIST = '/get/employer/pool/talent/list';
export const GET_VACANCY_POOL_LIST = '/get/employer/pool/vacancy/list';

//Settings
export const GET_USER_SETTINGS = '/get/employer/settings/user/myaccount';
export const GET_NOTIFICATION_SETTINGS = '/get/employer/settings/user/notification/emails';
export const POST_USER_SETTINGS = '/post/employer/settings/user/myaccount';
export const POST_PASSWORD_RESET = '/post/employer/password/reminder';
export const POST_NOTIFICATION_SETTINGS = '/post/employer/settings/user/notification/emails';
export const POST_ORGANISATION_NAME_URL = '/post/employer/org/update';

//Inbound
// export const GET_INBOUND_DASHBOARD_STATS = 'get/employer/inbound/dashboard?date_filter_days=10000';
export const GET_INBOUND_DASHBOARD_STATS_URL = '/get/employer/inbound/dashboard';

//Referencing Dashboard
export const GET_REFERENCING_DASHBOARD_STATS_URL = '/get/employer/referencing/dashboard';

//Candidate Forms
export const GET_CANDIDATE_FORM_DETAILS_URL = '/get/referee/basic';
export const POST_CANDIDATE_DRAFT_DATA = '/post/request/draft-data';
export const POST_NEW_REFERENCE = '/post/referee/add';
export const POST_REFEREE_VERIFY_DETAILS = '/post/referee/add-verify-details';
