import { AddCircleOutline, InfoOutlined, WalletOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Menu, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Routes from '../../constants/routes.js';
import { track } from '../../utils/analytics';

function WalletMenu({ anchorEl, handleClose, user, isSuperAdmin }) {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();

    const { creditBal, paidRTWCreditBal, dbsBasicCredits, dbsEnhancedCredits } = user;

    const creditTypes = [
        { id: 'reference', value: creditBal },
        { id: 'digitalId', value: paidRTWCreditBal },
        { id: 'dbsBasic', value: dbsBasicCredits },
        { id: 'dbsEnhanced', value: dbsEnhancedCredits },
    ].filter(({ value }) => value !== undefined);

    const hasLowCredits = creditTypes.some(({ value }) => value <= 5);

    const handleBuyCredits = () => {
        handleClose();
        track('Navigate to Buy Credits page [Wallet Menu]');
        navigate(Routes.settings.buyCredits);
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disablePortal
            keepMounted
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(-6px 0 10px #312c5d24)',
                        borderRadius: '10px',
                        mt: 1.5,
                        minWidth: '300px',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Box sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                    <WalletOutlined sx={{ color: theme.palette.secondary.main }} />
                    <Typography variant="subtitle2" color="secondary.main">
                        {formatMessage({ id: 'Header.wallet.title' })}
                    </Typography>
                </Stack>

                <Stack spacing={0.5}>
                    {creditTypes.map(({ id, value }) => (
                        <Box
                            key={id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                py: 0.75,
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                {formatMessage({
                                    id: `Header.account.credits.${id}`,
                                })}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color:
                                        value <= 5 ? theme.palette.secondary.main : 'text.primary',
                                    fontWeight: 600,
                                }}
                            >
                                {value}
                            </Typography>
                        </Box>
                    ))}
                </Stack>

                {hasLowCredits && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 1,
                            mt: 2,
                            p: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 1,
                            maxWidth: '280px',
                        }}
                    >
                        <InfoOutlined
                            sx={{ color: theme.palette.secondary.main, fontSize: '1.2rem' }}
                        />
                        <Typography variant="caption" color="text.secondary">
                            {formatMessage({ id: 'Header.wallet.lowCreditsWarning' })}
                        </Typography>
                    </Box>
                )}
            </Box>

            {isSuperAdmin && (
                <Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleBuyCredits}
                            startIcon={<AddCircleOutline />}
                            sx={{ textTransform: 'none' }}
                        >
                            {formatMessage({ id: 'Header.admin.buyCredits' })}
                        </Button>
                    </Box>
                </Box>
            )}
        </Menu>
    );
}

export default WalletMenu;
